import React, {Component} from 'react';
import {ExtendedDescription} from "./RepertoryItemExtendDescription";
class RepertoryItems extends Component{
    render(){
        const {title, director, details, place, img: image, date, link, free = false} = this.props.data;

        let time = new Date(date);
        time = time.toLocaleString('pl-PL', {
            timeZone: 'UTC',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        });

        return(
            <div className="col-lg-12 col-sm-12 h-100">
                    <div className="blog_post d-flex flex-column h-100">
                        <div className="blog_img">
                            <img className="img-fluid" src={image} alt=""/>
                        </div>
                        <div className="post_content d-flex flex-column">
                            <div className="blog-meta">
                                <span>
                                    <i className="icon_tags_alt"></i>
                                    {time}
                                </span>
                            </div>
                            <h2>{title}</h2>
                            <ExtendedDescription place={place} director={director} desc={details} />
                            <p className={'text-right pt-4'}>
                                <a className="btn btn-primary" href={link} rel="noopener noreferrer" target="_blank" role="button">{free ? 'Odbierz wejściówkę' : 'Kup bilet'}</a>
                            </p>
                        </div>
                    </div>
            </div>
        )
    }
}

export default RepertoryItems;
