import React, {useCallback, useState} from "react";

export const ExtendedDescription = ({ desc, place, director }) => {
  const [shortDesc,setShortDesc] = useState(true);

  const toggleDesc = useCallback(() => {
    setShortDesc(!shortDesc);
  },[shortDesc])

  return(<span onClick={toggleDesc} className="post_content-desc">
              <span>{place}<br/></span>
              <span>{director}<br/></span>
              <span>{shortDesc ? desc.substr(0,200).concat(' [więcej]') : desc}</span>
            </span>);
}
